<template>
    <div class="district_continue">
        <!-- 数据范围筛选 -->
        <div class="row_box">
            <div class="tit_box">
                <small-title tit="数据范围筛选"></small-title>
            </div>
            <!-- 参数 -->
            <div class="search_box">
                <div class="li">
                    <h6>省份：</h6>
                    <el-select v-model="querys.province" placeholder="全部" filterable clearable multiple collapse-tags
                        :loading="dicts.province.loading" @change="changeProvince">
                        <el-option v-for="item in dicts.province.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>城市：</h6>
                    <el-select v-model="querys.city" placeholder="全部" filterable clearable multiple collapse-tags
                        :loading="dicts.city.loading">
                        <el-option v-for="item in dicts.city.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>餐饮类型：</h6>
                    <el-select v-model="querys.mainType" placeholder="全部" filterable clearable multiple collapse-tags
                        :loading="dicts.mainType.loading" @change="changeMainType">
                        <el-option v-for="item in dicts.mainType.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>菜系类型：</h6>
                    <el-select v-model="querys.cuisineType" placeholder="全部" filterable clearable multiple collapse-tags
                        :loading="dicts.cuisineType.loading" @change="changeCuisineType">
                        <el-option v-for="item in dicts.cuisineType.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>菜系：</h6>
                    <el-select v-model="querys.cuisine" placeholder="全部" filterable clearable multiple collapse-tags
                        :loading="dicts.cuisine.loading">
                        <el-option v-for="item in dicts.cuisine.list" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>门店数范围：</h6>
                    <div class="range_input">
                        <el-input type="number" placeholder="门店数" v-model.number="querys.brandShopNumStart"></el-input>-
                        <el-input type="number" placeholder="门店数" v-model.number="querys.brandShopNumEnd"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>平均客单价范围</h6>
                    <div class="range_input">
                        <el-input type="number" placeholder="平均客单价" v-model.number="querys.brandPriceStart"></el-input>-
                        <el-input type="number" placeholder="平均客单价" v-model.number="querys.brandPriceEnd"></el-input>
                    </div>
                </div>
                <div class="li">
                    <h6>批次：</h6>
                    <el-select v-model="querys.batch" placeholder="请选择批次" filterable collapse-tags
                        :loading="dicts.batch.loading">
                        <el-option v-for="item in dicts.batch.list " :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="li">
                    <h6>比率：</h6>
                    <el-radio-group v-model="querys.ratioType">
                        <el-radio-button label="环比" />
                        <el-radio-button label="同比" />
                        <el-radio-button label="前2季度" />
                        <el-radio-button label="前3季度" />
                    </el-radio-group>
                </div>
                <div class="li">
                    <h6>统计对象：</h6>
                    <el-radio-group v-model="querys.isCity">
                        <el-radio-button :label="1">城市</el-radio-button>
                        <el-radio-button :label="0">区县</el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <div class="btn_box">
                <el-button type="info" @click="resetFn">重置</el-button>
                <el-button type="primary" @click="search">搜索</el-button>
            </div>
        </div>
        <!-- 表格-连续性 -->
        <div class="row_box">
            <div class="tit_box">
                <small-title :tit="`连续性数据-${querys.isCity === 1 ? '城市' : '区县'}`"></small-title>
                <el-button type="primary" @click="exportExcel">导出excel<el-icon class="el-icon--right">
                        <Right />
                    </el-icon>
                </el-button>
            </div>
            <div class="table_box">
                <el-table :data="tableData" stripe border style="width: 100%" height="600"
                    :header-cell-style="headerCellStyleHandle" :cell-style="cellStyleHandle">
                    <el-table-column prop="city" label="城市" show-overflow-tooltip fixed="left" align="center" />
                    <el-table-column prop="district" label="区县" show-overflow-tooltip fixed="left" align="center"
                        v-if="querys.isCity === 0" />
                    <el-table-column prop="tier" label="Tier" show-overflow-tooltip fixed="left" align="center"
                        v-if="querys.isCity === 0" />
                    <el-table-column v-for="item in titleData" :key="item" :label="item" align="center">
                        <el-table-column :prop="`${item}_total_score_`" label="总得分" align="center" />
                        <el-table-column :prop="`${item}_scope_score_`" label="规模总得分" min-width="100" align="center" />
                        <el-table-column :prop="`${item}_growth_score_`" label="成长总得分" min-width="100" align="center" />
                        <el-table-column :prop="`${item}_innovate_score_`" label="创新总得分" min-width="100" align="center" />
                    </el-table-column>
                </el-table>
                <div class="no_data" v-if="!tableData.length">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <div class="page_box">
                    <el-pagination layout="prev, pager, next" background :total="total" :page-size="pages.pageSize"
                        v-model:current-page="pages.pageNum">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template> 
  
<script setup>
import SmallTitle from "@/components/common/SmallTitle.vue";
import { reactive, ref, watch } from "vue";
import { Right } from "@element-plus/icons-vue";
import useParams from './useParams';
import useWeight from '../comp/useWeight';
import { apiCountyPotentialContinuity } from '@/request/api';
import withLoading from '@/utils/loading';
import useReset from './useReset';
import useExportExcel from './useExportExcel';
import useTableStyle from './useTableStyle';

const pages = reactive({ pageNum: 1, pageSize: 100 });
const titleData = ref([]);
const total = ref(0);
const tableData = ref([]);

// 字典/参数
const { dicts, querys, changeProvince, changeMainType, changeCuisineType } = useParams();

// 权重
const { getWeightConfig } = useWeight();

// 获取列表
async function getList() {
    const params = { ...pages, ...querys, ...getWeightConfig() }
    const { data } = await withLoading(apiCountyPotentialContinuity)(params);
    titleData.value = data?.title || [];
    total.value = data?.total || 0;
    tableData.value = data?.list || [];
}

// 分页
watch(() => pages.pageNum, getList);

// 搜索
function search() {
    pages.pageNum = 1;
    getList();
}

// 重置
const { resetFn } = useReset({ dicts, querys, pages, titleData, total, tableData });

// 导出
const { exportExcel } = useExportExcel({ tableData, querys });

// table样式
const { headerCellStyleHandle, cellStyleHandle } = useTableStyle();

</script>
  
<style lang="scss" scoped>
.district_continue {

    .row_box {
        background: #fff;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 10px;

        .tit_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .search_box {
            display: flex;
            flex-wrap: wrap;
            padding: 0 20px;
            padding-top: 20px;

            .li {
                margin-right: 20px;
                margin-bottom: 10px;

                h6 {
                    font-weight: normal;
                    color: #818a9b;
                    padding-bottom: 6px;
                    font-size: 14px;
                }

                .el-select {
                    width: 200px;
                }

                :deep(.el-input__inner) {
                    border-radius: 7px;
                }

                .range_input {
                    display: flex;
                    align-items: center;

                    .el-input {
                        width: 100px;
                    }
                }

                &.two_select {
                    .el-select {
                        width: 150px;
                        margin-right: 15px;
                    }
                }
            }
        }

        .btn_box {
            text-align: center;
            padding-top: 10px;
        }

        .table_box {
            position: relative;
            padding-top: 15px;

            .no_data {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-top: 180px;
            }
        }
    }

    .page_box {
        text-align: right;
        padding: 20px 0;
    }
}

:deep(.el-table__empty-text) {
    display: none;
}

// 滚动条的宽度
:deep(.el-table__body-wrapper::-webkit-scrollbar) {
    width: 10px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
}

// 滚动条的滑块
:deep(.el-table__body-wrapper::-webkit-scrollbar-thumb) {
    background-color: #ddd;
    border-radius: 5px;
    cursor: pointer;
}
</style>
  