export default ({ dicts, querys, pages, titleData, total, tableData }) => {

    function resetFn() {
        querys.province = [];
        querys.city = [];
        querys.mainType = [];// 餐饮类型
        querys.cuisineType = [];// 菜系类型
        querys.cuisine = [];// 菜系
        querys.brandShopNumStart = null;// 门店数开始
        querys.brandShopNumEnd = null;// 门店数结束
        querys.brandPriceStart = null;// 客单价开始
        querys.brandPriceEnd = null;// 客单价结束
        querys.batch = dicts.batch.list[0];// 批次
        querys.ratioType = '环比';// 比率
        querys.isCity = 1;// 统计对象 城市=1 区县=0
        pages.pageNum = 1;
        titleData.value = [];
        total.value = 0;
        tableData.value = [];
    }

    return { resetFn }
}