import { reactive, ref } from 'vue';
import { apiCountyPotentialDict } from '@/request/api';
import { debounce } from '@/utils';
import withLoading from '@/utils/loading';

export default () => {

    // 已选参数
    const querys = reactive({
        province: [],
        city: [],
        mainType: [],// 餐饮类型
        cuisineType: [],// 菜系类型
        cuisine: [],// 菜系
        brandShopNumStart: null,// 门店数开始
        brandShopNumEnd: null,// 门店数结束
        brandPriceStart: null,// 客单价开始
        brandPriceEnd: null,// 客单价结束
        batch: '',// 批次
        ratioType: '环比',// 比率
        isCity: 1,// 统计对象 城市=1 区县=0
    })

    // 字典
    const dicts = reactive({
        province: { loading: false, list: [] },
        city: { loading: false, list: [] },
        mainType: { loading: false, list: [] },// 餐饮类型
        cuisineType: { loading: false, list: [] },// 菜系类型
        cuisine: { loading: false, list: [] },// 菜系
        batch: { loading: false, list: [] },// 批次
    })

    // 获取省份
    async function getProvince() {
        dicts.province.loading = true;
        try {
            const { data } = await apiCountyPotentialDict({ code: 'province' });
            dicts.province.list = data || [];
        } finally {
            dicts.province.loading = false;
        }
    }

    // 省份改变
    const changeProvince = debounce(() => {
        querys.city = [];
        getCity();
    }, 800)

    // 获取城市
    async function getCity() {
        dicts.city.loading = true;
        try {
            const { data } = await apiCountyPotentialDict({ code: 'city', province: querys.province });
            dicts.city.list = data || [];
        } finally {
            dicts.city.loading = false;
        }
    }

    // 获取餐饮类型
    async function getMainType() {
        dicts.mainType.loading = true;
        try {
            const { data } = await apiCountyPotentialDict({ code: 'cuisine' });
            dicts.mainType.list = data || [];
        } finally {
            dicts.mainType.loading = false;
        }
    }

    // 餐饮类型改变
    const changeMainType = debounce(() => {
        querys.cuisineType = [];
        querys.cuisine = [];
        getCuisineType();
        getCuisine();
    }, 800)

    // 获取菜系类型
    async function getCuisineType() {
        dicts.cuisineType.loading = true;
        try {
            const { data } = await apiCountyPotentialDict({ code: 'cuisine1', cuisine1: querys.mainType });
            dicts.cuisineType.list = data || [];
        } finally {
            dicts.cuisineType.loading = false;
        }
    }

    // 菜系类型改变
    const changeCuisineType = debounce(() => {
        querys.cuisine = [];
        getCuisine();
    }, 800)

    // 获取菜系
    async function getCuisine() {
        dicts.cuisine.loading = true;
        try {
            const { data } = await apiCountyPotentialDict({ code: 'cuisine2', cuisine1: querys.mainType, cuisine2: querys.cuisineType });
            dicts.cuisine.list = data || [];
        } finally {
            dicts.cuisine.loading = false;
        }
    }

    // 获取批次
    async function getBatch() {
        const { data } = await withLoading(apiCountyPotentialDict)({ code: 'batch' });
        dicts.batch.list = data || [];
        querys.batch = dicts.batch.list[0];
    }

    getProvince();// 获取省份
    getCity();// 获取城市
    getMainType();// 获取餐饮类型
    getCuisineType();// 获取菜系类型
    getCuisine();// 获取菜系
    getBatch();// 获取批次

    return { dicts, querys, changeProvince, changeMainType, changeCuisineType }
}